<div class="bg-img">
    <section class="d-flex text-center align-items-center position-relative">
        <div class="register-form-container container-fluid position-relative">
            <div class="container">
                <div class="row min-vh-100">
                    <div class="col-xl-6 col-lg-7 col-md-9 col-sm-11 mx-auto text-center form p-4 wrap-items">
                        <h3 class="meshini-name display-4 py-2 text-truncate">Meshini</h3>
                        <div>
                            <h2 class="success-message">Account activated successfully</h2>
                            <img class="checked-icon" id="image"
                                src="../../assets/images/drawable-xxxhdpi/checked.png" />
                        </div>
                        <p> Tips: <br> Guides offers lasts for only 5 min. then it will be declined automatically</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>