import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountActivatedComponent } from './account-activated/account-activated.component';
import { DisableDeepLinksGuard } from './shared/guards/disable-deeplinks.guard';

@NgModule({
  declarations: [
    AppComponent, AccountActivatedComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [DisableDeepLinksGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
