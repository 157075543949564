import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class DisableDeepLinksGuard implements CanActivate {
    constructor (private router: Router) { }

    public canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (window.location.href.indexOf(state.url.split('#')[0].split('?')[0]) >= 0) {

            window.location.href = window.location.origin + '/auth/login'

            return false;
        }

        return true;
    }

    public canActivateChild (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.canActivate(route, state);
    }
}
